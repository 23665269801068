import { useCallback, useContext } from "react";
import TableDataContext from "../context/DataContext/DataContext";
import { useSnackbar } from "notistack";
import { requestApi } from "../helpers";

const useRequestApi = () => {
  const { setLoading, setData } = useContext(TableDataContext);

  const { enqueueSnackbar } = useSnackbar();

  const fetchDataObject = useCallback(
    async (fetchParams) => {
      setLoading(true);
      try {
        const { data } = await requestApi(fetchParams);
        setData(data);
      } catch (err) {
        setData({});
        enqueueSnackbar("Algo deu errado!", { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, setData, setLoading]
  );

  const postDataObject = useCallback(
    async (postParams, fetchParams) => {
      setLoading(true);
      try {
        await requestApi(postParams);
        enqueueSnackbar("Requisição feita com sucesso!", { variant: "info" });
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Algo deu errado!", { variant: "error" });
      } finally {
        setLoading(false);
        setData(fetchDataObject(fetchParams));
      }
    },
    [enqueueSnackbar, fetchDataObject, setData, setLoading]
  );

  return { fetchDataObject, postDataObject };
};

export default useRequestApi;
