import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  footerPage: {
    marginRight: 15,
    display: "block",
  },
}));

export default useStyles;
