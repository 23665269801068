import React from "react";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import { ptBR } from "@material-ui/core/locale";

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#6417ad" },
    },
  },
  ptBR
);

const globalStyle = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default globalStyle;
