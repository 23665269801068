import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";

import React, { useContext, useState } from "react";

import ToolsContext from "../../context/ToolsContext/ToolsContext";

const UploadTool = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { handleUpload } = useContext(ToolsContext);

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        <CloudUpload />
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Informe os dados</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Título do relatório"
                name="name"
                autoComplete="name"
                value={inputValue}
                onChange={({ target }) => setInputValue(target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="Button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleUpload(inputValue)}
          >
            Enviar Relatório
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadTool;
