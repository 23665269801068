import { Grid, Typography, Breadcrumbs } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { formatPath } from "../../helpers/formatCurrentPath";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CustomBreadcrumbs = () => {
  const formattedPathArray = formatPath(window.location.pathname);
  const history = useHistory();
  const setColor = (i) => {
    if (i === formattedPathArray.length - 1) return "textPrimary";
    return "inherit";
  };

  const navigateTo = (path) => {
    const pathIndex = formattedPathArray.findIndex((i) => i === path);
    const navigatePath = formattedPathArray.slice(0, pathIndex + 1).join("/");
    history.push(`/${navigatePath}`);
  };

  return (
    <Grid item md={12} xs={12}>
      <Breadcrumbs separator="›">
        <Link color="inherit" to="/">
          Dashboard
        </Link>
        {formattedPathArray.map((path, i) => (
          <Typography
            key={i}
            color={setColor(i)}
            onClick={() => navigateTo(path)}
          >
            {path}
          </Typography>
        ))}
      </Breadcrumbs>
    </Grid>
  );
};

export default CustomBreadcrumbs;
