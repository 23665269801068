import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";

const CreateTool = ({ handleClick = () => {} }) => {
  return (
    <Button variant="outlined" color="primary" onClick={() => handleClick()}>
      <Add />
    </Button>
  );
};

export default CreateTool;
