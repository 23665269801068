import React, { useState } from "react";
import TableDataContext from "./DataContext";

function DataProvider({ children }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const state = {
    data,
    setData,
    loading,
    setLoading,
  };

  return (
    <TableDataContext.Provider value={state}>
      {children}
    </TableDataContext.Provider>
  );
}

export default DataProvider;
