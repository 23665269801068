import React, { useState, useEffect } from "react";

import {
  Toolbar,
  Container,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Button,
  InputBase,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Zoom,
  Breadcrumbs,
  Link,
  Typography,
  Avatar,
  TextField,
} from "@material-ui/core";

import { Edit, Search, VpnKey, Info } from "@material-ui/icons";

import { Skeleton } from "@material-ui/lab";

import { format } from "date-fns";

import { DebounceInput } from "react-debounce-input";

import { useSnackbar } from "notistack";

import { Page } from "../../../components";

import useStyles from "./styes";

import { levanteBotGateway } from "../../../services/api";

const DashboardAuth = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [dataAuth, setDataAuth] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogSignature, setopenDialogSignature] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(1);
  const [skeletonRows] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [selectedLead, setSelectedLead] = useState({});
  const [signatures, setSignatures] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [cachedDataSearch, setCachedDataSearch] = useState(null);

  const handleDelete = () => {
    levanteBotGateway
      .patch(`/users/?token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`, {
        id: selectedLead.id,
        email: selectedLead.new_email,
      })
      .then(({ data }) => {
        if (data.error) {
          return enqueueSnackbar(`${data.error}`, { variant: "error" });
        }

        enqueueSnackbar(`${selectedLead.new_email} atualizado com sucesso.`, {
          variant: "success",
        });

        setCachedDataSearch(null);
        setRefresh(!refresh);
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}.`, { variant: "error" });
      });
  };

  const handleSearchData = (value) => {
    setPage(0);
    setCachedDataSearch(value);
  };

  const handleChangeUpdateEmail = (event) => {
    event.persist();

    setSelectedLead((lead) => ({
      ...lead,
      new_email: event.target.value,
    }));
  };

  const handleCheckSignature = ({ email }) => {
    levanteBotGateway
      .get(
        `/signatures/${email}?skip=0&limit=10&token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`
      )
      .then(({ data }) => {
        setSignatures(data.subscriptions);
      })
      .catch((err) => {});
  };

  const handleClose = (action, popup) => {
    setOpenDialog(false);
    setopenDialogSignature(false);

    if (action) {
      return handleDelete();
    }

    setSelectedLead({});
    setSignatures(false);

    if (popup) {
      enqueueSnackbar("Operação cancelada.", { variant: "info" });
    }
  };

  useEffect(() => {
    setLoading(true);

    function loadUsers() {
      let query = cachedDataSearch ? `users/${cachedDataSearch}` : "users";

      levanteBotGateway
        .get(
          `/${query}?skip=${
            page * rowsPerPage
          }&limit=${rowsPerPage}&token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`
        )
        .then(({ data }) => {
          setLoading(false);
          setCount(data[0].total);
          setDataAuth(data.slice(1, data.length));
        });
    }

    loadUsers();
  }, [page, rowsPerPage, refresh, cachedDataSearch]);

  return (
    <Page title="BOT - Auth" classes={classes}>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Breadcrumbs separator="›">
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">BOT - Auth</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <Toolbar>
                <VpnKey size={15} />
                <CardHeader
                  className={classes.title}
                  title={`BOT - Auth ${count > 1 ? `${count} usuários` : ""}`}
                />
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <Search />
                  </div>
                  <DebounceInput
                    element={InputBase}
                    minLength={2}
                    debounceTimeout={500}
                    placeholder="Localizar..."
                    onChange={(e) => handleSearchData(e.target.value)}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </Toolbar>
              <Container className={classes.cardContentFixedHeight}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.stickyheader} />
                      <TableCell className={classes.stickyheader} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading
                      ? skeletonRows.map((element) => (
                          <TableRow key={element}>
                            <TableCell colSpan={6}>
                              <Skeleton animation="wave" height={44} />
                            </TableCell>
                          </TableRow>
                        ))
                      : dataAuth.map((row) => {
                          const user = row.email || row.contact_id;

                          return (
                            <TableRow key={row._id}>
                              <TableCell>
                                <Grid container wrap="nowrap" spacing={1}>
                                  <Grid item>
                                    <Avatar>
                                      {user.toUpperCase().substring(0, 1)}
                                    </Avatar>
                                  </Grid>
                                  <Grid item xs>
                                    <div className={classes.subtitle}>
                                      {user}
                                    </div>
                                    <div className={classes.description}>
                                      {format(
                                        new Date(row.createdAt),
                                        "dd/MM/yyyy HH:mm"
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  title="Checar assinaturas"
                                >
                                  <Button
                                    className={classes.deleteButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      setopenDialogSignature(true);
                                      handleCheckSignature({
                                        email: row.email,
                                      });
                                    }}
                                  >
                                    <Info />
                                  </Button>
                                </Tooltip>
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  title="Editar Lead"
                                >
                                  <Button
                                    className={classes.deleteButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      setOpenDialog(true);
                                      setSelectedLead({
                                        id: row._id,
                                        email: row.email || row.contact_id,
                                      });
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </Container>
            </CardContent>

            <CardActions className={classes.footerPage}>
              <TablePagination
                component="div"
                count={count || 1}
                page={page}
                onChangePage={(_event, value) => {
                  setPage(value);
                }}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(event.target.value);
                  setPage(0);
                }}
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialog}
        onClose={(e) => handleClose(false, true)}
      >
        <DialogTitle id="form-dialog-title">
          Editar: {selectedLead.email || selectedLead.contact_id}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para atualizar este usuário, digite o novo endereço de e-mail:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Novo email"
            type="email"
            onChange={handleChangeUpdateEmail}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(true, true)}
            color="primary"
            autoFocus
            disabled={!selectedLead.new_email || !selectedLead.email}
          >
            Sim
          </Button>
          <Button onClick={() => handleClose(false, true)} color="primary">
            Não
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialogSignature}
        onClose={(e) => handleClose(false, false)}
      >
        <DialogTitle id="form-dialog-title">Assinaturas ativas:</DialogTitle>
        <DialogContent>
          {signatures.length > 0 ? (
            signatures.map((signature) => {
              return <DialogContentText>{signature.name}</DialogContentText>;
            })
          ) : (
            <DialogContentText>Sem assinaturas.</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false, false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default DashboardAuth;
