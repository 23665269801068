/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const ChatbotFormDialog = ({
  openDialog,
  setOpenDialog,
  editing = false,
  chatbotInfo = { id: "", username: "", token: "", status: "active" },
  setChatbotInfo,
  handleClick,
}) => {
  const { username, token, status } = chatbotInfo;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle id="form-dialog-title">
        {editing ? "Editar Chatbot" : "Criar Chatbot"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {editing
            ? `Para atualizar ${username}, preencha os campos abaixo:`
            : "Preencha os campos abaixo"}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="username"
          label="Username"
          type="text"
          value={username}
          onChange={({ target }) =>
            setChatbotInfo((prevState) => ({
              ...prevState,
              username: target.value,
            }))
          }
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="token"
          label="token"
          value={token}
          type="text"
          onChange={({ target }) =>
            setChatbotInfo((prevState) => ({
              ...prevState,
              token: target.value,
            }))
          }
          fullWidth
        />
        <Select
          autoFocus
          margin="dense"
          id="status"
          label="status"
          value={status}
          onChange={({ target }) =>
            setChatbotInfo((prevState) => ({
              ...prevState,
              status: target.value,
            }))
          }
          fullWidth
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClick()} color="primary" autoFocus>
          Sim
        </Button>
        <Button
          onClick={() => {
            setOpenDialog(false);
            setChatbotInfo({});
          }}
          color="primary"
        >
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatbotFormDialog;
