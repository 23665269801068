import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { requestApi } from "../../helpers/requestApi";

const IndicatorsDialog = ({
  openDialog,
  setOpenDialog,
  indicatorCode,
  getIndicators,
}) => {
  const [logo, setLogo] = useState();

  const updateLogo = async () => {
    const formData = new FormData();
    formData.append("stock_exchange[logo]", logo);
    await requestApi({
      method: "PUT",
      endpoint: `indicators/${indicatorCode}`,
      params: {},
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    await getIndicators();
    setOpenDialog(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle id="form-dialog-title">Editar: Logo</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Para atualizar a logo de ${indicatorCode}, adicione uma imagem.`}
        </DialogContentText>
        <input
          autoFocus
          margin="dense"
          id="logo"
          label="Nova logo"
          type="file"
          onChange={({ target }) => setLogo(target.files[0])}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => updateLogo()} color="primary" autoFocus>
          Sim
        </Button>
        <Button
          onClick={() => {
            setOpenDialog(false);
            setLogo(null);
          }}
          color="primary"
        >
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IndicatorsDialog;
