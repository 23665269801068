import React, { useState, useEffect } from "react";

import {
  Toolbar,
  Container,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  InputBase,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Zoom,
  Breadcrumbs,
  Link,
  Typography,
  Avatar,
} from "@material-ui/core";

import { Delete, Search, Telegram } from "@material-ui/icons";

import { Skeleton } from "@material-ui/lab";

import { format } from "date-fns";

import { DebounceInput } from "react-debounce-input";

import { useSnackbar } from "notistack";

import { Page } from "../../../components";

import { levanteMessaging } from "../../../services/api";

import useStyles from "./styles";

const DashboardTelegram = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [dataTelegram, setDataTelegram] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(1);
  const [skeletonRows] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [selectedLead, setSelectedLead] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [cachedDataSearch, setCachedDataSearch] = useState(null);

  const handleDelete = () => {
    levanteMessaging
      .delete(
        `/telegram/${selectedLead.id}/delete?token=8cb3eab1-p2o8-54c1-rt18-hs25gwa9aih7`
      )
      .then(() => {
        enqueueSnackbar(
          `${selectedLead.email || selectedLead.id} deletado com sucesso.`,
          {
            variant: "success",
          }
        );

        setCachedDataSearch(null);
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}.`, { variant: "error" });
      });

    setRefresh(!refresh);
  };

  const handleSearchData = (value) => {
    setPage(0);
    setCachedDataSearch(value);
  };

  const handleClose = (action) => {
    setOpenDialog(false);

    if (action) {
      return handleDelete();
    }

    setSelectedLead({});

    enqueueSnackbar("Operação cancelada.", { variant: "info" });
  };

  useEffect(() => {
    setLoading(true);

    function loadUsers() {
      let query = cachedDataSearch
        ? `telegram/${cachedDataSearch}`
        : "telegram";

      levanteMessaging
        .get(
          `/${query}?skip=${
            page * rowsPerPage
          }&limit=${rowsPerPage}&token=8cb3eab1-p2o8-54c1-rt18-hs25gwa9aih7`
        )
        .then(({ data }) => {
          setLoading(false);
          setCount(data[0].total);
          setDataTelegram(data.slice(1, data.length));
        });
    }

    loadUsers();
  }, [page, rowsPerPage, refresh, cachedDataSearch]);

  return (
    <Page title="Telegram" classes={classes}>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Breadcrumbs separator="›">
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">Telegram</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <Toolbar>
                <Telegram size={15} />
                <CardHeader
                  className={classes.title}
                  title={`Telegram ${count > 1 ? `${count} lead's` : ""}`}
                />
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <Search />
                  </div>
                  <DebounceInput
                    element={InputBase}
                    minLength={2}
                    debounceTimeout={500}
                    placeholder="Localizar..."
                    onChange={(e) => handleSearchData(e.target.value)}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </Toolbar>
              <Container className={classes.cardContentFixedHeight}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.stickyheader} />
                      <TableCell className={classes.stickyheader} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading
                      ? skeletonRows.map((element) => (
                          <TableRow key={element}>
                            <TableCell colSpan={6}>
                              <Skeleton animation="wave" height={44} />
                            </TableCell>
                          </TableRow>
                        ))
                      : dataTelegram.map((row) => {
                          const user = row.email || row.contact_id;

                          return (
                            <TableRow key={row._id}>
                              <TableCell>
                                <Grid container wrap="nowrap" spacing={1}>
                                  <Grid item>
                                    <Avatar>
                                      {user.toUpperCase().substring(0, 1)}
                                    </Avatar>
                                  </Grid>
                                  <Grid item xs>
                                    <div className={classes.subtitle}>
                                      {user}
                                    </div>
                                    <div className={classes.description}>
                                      {format(
                                        new Date(row.createdAt),
                                        "dd/MM/yyyy HH:mm"
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  title="Deletar Lead"
                                >
                                  <Button
                                    className={classes.deleteButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      setOpenDialog(true);
                                      setSelectedLead({
                                        id: row.contact_id,
                                        email: row.email || row.contact_id,
                                      });
                                    }}
                                  >
                                    <Delete />
                                  </Button>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </Container>
            </CardContent>

            <CardActions className={classes.footerPage}>
              <TablePagination
                component="div"
                count={count || 1}
                page={page}
                onChangePage={(_event, value) => {
                  setPage(value);
                }}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(event.target.value);
                  setPage(0);
                }}
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={(e) => handleClose(false)}>
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente deletar:{" "}
            <b>{selectedLead.email || "Não definido."}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            Sim
          </Button>
          <Button onClick={() => handleClose(false)} color="primary">
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default DashboardTelegram;
