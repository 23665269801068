import React, { useState } from "react";

import clsx from "clsx";

import {
  AppBar,
  Toolbar,
  Typography,
  Divider,
  Badge,
  Drawer,
  IconButton,
} from "@material-ui/core";

import { Menu, ChevronLeft, Notifications } from "@material-ui/icons";

import {
  // mainListItems,
  containerLevanteCorpList,
  containerList,
  setupList,
  secondaryListItems,
  levanteList,
  chatBots,
} from "./Items";

const DrawerComponent = ({ title, classes }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <Menu />
          </IconButton>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Levante
          </Typography>
          <IconButton color="inherit" disabled>
            <Badge badgeContent={1} color="secondary">
              <Notifications />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeft />
          </IconButton>
        </div>

        {/* <Divider />
        {mainListItems} */}
        <Divider />
        {containerList}
        <Divider />
        {containerLevanteCorpList}
        <Divider />
        {setupList}
        <Divider />
        {levanteList}
        <Divider />
        {chatBots}
        <Divider />
        {secondaryListItems}
      </Drawer>
    </>
  );
};

export default DrawerComponent;
