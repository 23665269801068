import useStyles from "./styles";
import {
  Avatar,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import React, { useState } from "react";
import { Delete, Edit } from "@material-ui/icons";
import { ChatbotChannelsFormDialog, DeleteDialog } from "../../dialogs";
import { setAvatar } from "../../../helpers";
import useRequestApi from "../../../hooks/useRequestApi";
import { chatbotChannels } from "../../../shared/requests";

const ChatbotChannelsTable = ({ tableData = [], chatbots = [] }) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [channelInfo, setChannelInfo] = useState({});
  const { postDataObject } = useRequestApi();
  const classes = useStyles();

  const handleSubmit = async () => {
    await postDataObject(
      chatbotChannels.patch(channelInfo),
      chatbotChannels.get
    );
  };

  const handleDelete = async () => {
    const { id } = channelInfo;
    await postDataObject(chatbotChannels.delete({ id }), chatbotChannels.get);
  };

  return (
    <Container className={classes.cardContentFixedHeight}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.stickyheader} />
            <TableCell className={classes.stickyheader} />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map(({ _id, name, channel_id, ext_product_ids, bot }) => (
            <TableRow key={_id}>
              <TableCell>
                <Grid container wrap="nowrap" spacing={1}>
                  <Grid item>
                    <Avatar>{setAvatar(name)}</Avatar>
                  </Grid>
                  <Grid item xs>
                    <div className={classes.subtitle}>{name}</div>
                    <div className={classes.description}>{channel_id}</div>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="right">
                <Tooltip TransitionComponent={Zoom} title="Deletar canal">
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setChannelInfo({ id: _id, name, channel_id });
                      setOpenDeleteDialog(true);
                    }}
                  >
                    <Delete />
                  </Button>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="Editar canal">
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setChannelInfo({
                        id: _id,
                        name,
                        channel_id,
                        ext_product_ids,
                        bot: bot ? bot._id : "",
                      });
                      setOpenEditDialog(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ChatbotChannelsFormDialog
        openDialog={openEditDialog}
        setOpenDialog={setOpenEditDialog}
        channelInfo={channelInfo}
        setChannelInfo={setChannelInfo}
        handleClick={handleSubmit}
        chatbots={chatbots}
        editing={true}
      />
      <DeleteDialog
        id={channelInfo.id}
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        handleClick={handleDelete}
      />
    </Container>
  );
};

export default ChatbotChannelsTable;
