import Papa from "papaparse";


const fixCSV = (data) => {
  const firstColumn = Object.keys(data[0])[0];
  return data.filter((f) => f[firstColumn] !== "")
}

export const parseCSV = (file) => {
  return new Promise((resolve, _reject) => {
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: ({ data }) => {
          resolve(fixCSV(data));
        },
        error: () => {
          resolve([]);
        },
      });
    } else {
      resolve([]);
    }
  });
};