import { makeStyles } from "@material-ui/core/styles";

import Background from "../../../assets/background.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.primary.main,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: "19% 19%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default useStyles;
