import React from "react";

import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Toolbar,
  Typography,
  Breadcrumbs,
  Link,
  Button,
} from "@material-ui/core";

import { Dashboard } from "@material-ui/icons";

import { Page } from "../../../components";

import { create } from "axios";

import useStyles from "./styles";

const DashboardHome = () => {
  const classes = useStyles();

  const data = create({
    baseURL: "https://api.levanteideias.com.br/api/telegram_auth/small_caps",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  });

  const handleClick = () => {
    data
      .get(
        "/check",
        { crossDomain: true },
        {
          params: {
            email: "ernestoaf@gmail.com",
            token: "22b028a9-fe76-42ca-b53b-c427c0db03dc",
          },
        }
      )
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Page title="Dashboard" classes={classes}>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Breadcrumbs separator="›">
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">Home</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Toolbar>
                <Dashboard size={15} />
                <CardHeader className={classes.title} title="Home" />
                <Button onClick={handleClick}>CLICAR</Button>
              </Toolbar>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default DashboardHome;
