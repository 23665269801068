import React, { useState, useEffect } from "react";

import {
  Toolbar,
  Container,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Button,
  InputBase,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Zoom,
  Breadcrumbs,
  Link,
  Typography,
  Avatar,
  TextField,
  Checkbox,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Box,
} from "@material-ui/core";

import {
  Edit,
  Search,
  Group,
  Info,
  Settings,
  Gavel,
  ThumbUp,
} from "@material-ui/icons";

import { Skeleton } from "@material-ui/lab";

import { format } from "date-fns";

import { DebounceInput } from "react-debounce-input";

import { useSnackbar } from "notistack";

import { Page } from "../../../components";

import useStyles from "./styes";

import { levanteCorp } from "../../../services/api";

const DashboardAuth = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [dataAuth, setDataAuth] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogSignature, setOpenDialogSignature] = useState(false);
  const [openDialogSettings, setOpenDialogSettings] = useState(false);
  const [openDialogKickUser, setOpenDialogKickUser] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(1);
  const [skeletonRows] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [selectedLead, setSelectedLead] = useState({});
  const [signatures, setSignatures] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [cachedDataSearch, setCachedDataSearch] = useState(null);
  const [filter, setFilter] = useState({});
  const [channels, setChannels] = useState(false);

  const handleChange = (event) => {
    setPage(0);

    if (event.target.name !== "paid" && event.target.checked === false) {
      delete filter[`${event.target.name}`];

      return setFilter({ ...filter });
    }

    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    });
  };

  const handleUpdate = () => {
    levanteCorp
      .patch(
        `/users/${selectedLead.id}?token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`,
        {
          email: selectedLead.new_email,
        }
      )
      .then(({ data }) => {
        if (data.error) {
          return enqueueSnackbar(`${data.error}`, { variant: "error" });
        }

        enqueueSnackbar(`${selectedLead.new_email} atualizado com sucesso.`, {
          variant: "success",
        });

        setCachedDataSearch(null);
        setRefresh(!refresh);
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}.`, { variant: "error" });
      });
  };

  const handleKick = () => {
    levanteCorp
      .post(
        `/users/${selectedLead.id}?token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`
      )
      .then(({ data }) => {
        // if (data.error) {
        //   return enqueueSnackbar(`${data.error}`, { variant: "error" });
        // }

        // enqueueSnackbar(`${selectedLead.email} banido com sucesso.`, {
        //   variant: "success",
        // });

        // setCachedDataSearch(null);
        // setRefresh(!refresh);
        console.log(data);
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}.`, { variant: "error" });
      });
  };

  const handleUnKick = (id, email) => {
    levanteCorp
      .get(`/telegrams/unban/${id}?token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`)
      .then(({ data }) => {
        if (data.error) {
          return enqueueSnackbar(`${data.error}`, { variant: "error" });
        }

        enqueueSnackbar(`${email} desbanido com sucesso.`, {
          variant: "success",
        });

        setCachedDataSearch(null);
        setRefresh(!refresh);
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}.`, { variant: "error" });
      });
  };

  const handleSearchData = (value) => {
    setPage(0);
    setCachedDataSearch(value);
  };

  const handleChangeUpdateEmail = (event) => {
    event.persist();

    setSelectedLead((lead) => ({
      ...lead,
      new_email: event.target.value,
    }));
  };

  const handleCheckSignature = (email) => {
    levanteCorp
      .get(
        `/signatures/${email}?skip=0&limit=10&token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`
      )
      .then(({ data }) => {
        setSignatures(data);
      });
  };

  const handleChannelsUser = (id) => {
    levanteCorp
      .post(
        `/users/${id}?skip=0&limit=10&token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`,
        {
          bot: "LevanteCorpAuth_bot",
        }
      )
      .then(({ data }) => {
        setChannels(data);
      });
  };

  const handleClose = (action, popup) => {
    setOpenDialog(false);
    setOpenDialogSignature(false);
    setOpenDialogKickUser(false);

    if (action === "update") {
      return handleUpdate();
    }

    if (action === "kick") {
      return handleKick();
    }

    setSelectedLead({});
    setSignatures(false);

    if (popup) {
      enqueueSnackbar("Operação cancelada.", { variant: "info" });
    }
  };

  useEffect(() => {
    setLoading(true);

    async function loadUsers() {
      const response = cachedDataSearch
        ? await levanteCorp.post(
            `/users/?skip=${
              page * rowsPerPage
            }&limit=${rowsPerPage}&token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`,
            {
              email: cachedDataSearch,
            }
          )
        : await levanteCorp.post(
            `/users/?skip=${
              page * rowsPerPage
            }&limit=${rowsPerPage}&token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`,
            {
              ...filter,
            }
          );

      setLoading(false);
      setCount(response.data.total);
      setDataAuth(response.data.users);
    }

    loadUsers();
  }, [page, rowsPerPage, refresh, cachedDataSearch, filter]);

  return (
    <Page title="BOT - Auth" classes={classes}>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Breadcrumbs separator="›">
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="inherit">corp</Typography>
            <Typography color="textPrimary">BotAuth</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <Toolbar>
                <Group size={15} />
                <CardHeader
                  className={classes.title}
                  title={`BotAuth ${count > 1 ? `${count} usuários` : ""}`}
                />
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <Search />
                  </div>
                  <DebounceInput
                    element={InputBase}
                    minLength={2}
                    debounceTimeout={500}
                    placeholder="Localizar..."
                    onChange={(e) => handleSearchData(e.target.value)}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                  <Button onClick={() => setOpenDialogSettings(true)} disabled>
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Filtrar usuários"
                    >
                      <Settings />
                    </Tooltip>
                  </Button>
                </div>
              </Toolbar>
              <Container className={classes.cardContentFixedHeight}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.stickyheader} />
                      <TableCell className={classes.stickyheader} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading
                      ? skeletonRows.map((element) => (
                          <TableRow key={element}>
                            <TableCell colSpan={6}>
                              <Skeleton animation="wave" height={44} />
                            </TableCell>
                          </TableRow>
                        ))
                      : dataAuth.map((row) => {
                          const user = row.email || row.contact_id;

                          return (
                            <TableRow key={row._id}>
                              <TableCell>
                                <Grid container wrap="nowrap" spacing={1}>
                                  <Grid item>
                                    <Avatar>
                                      {user.toUpperCase().substring(0, 1)}
                                    </Avatar>
                                  </Grid>
                                  <Grid item xs>
                                    <div className={classes.subtitle}>
                                      {user}
                                    </div>
                                    <div className={classes.description}>
                                      {format(
                                        new Date(row.createdAt),
                                        "dd/MM/yyyy HH:mm"
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  title="Checar assinatura"
                                >
                                  <Button
                                    className={classes.deleteButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      setOpenDialogSignature(true);
                                      handleCheckSignature(row.email);
                                    }}
                                  >
                                    <Info />
                                  </Button>
                                </Tooltip>
                                <Tooltip
                                  TransitionComponent={Zoom}
                                  title="Editar usuário"
                                >
                                  <Button
                                    className={classes.deleteButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      setOpenDialog(true);
                                      setSelectedLead({
                                        id: row._id,
                                        email: row.email || row.contact_id,
                                      });
                                    }}
                                  >
                                    <Edit />
                                  </Button>
                                </Tooltip>
                                {row.banned ? (
                                  <Tooltip
                                    TransitionComponent={Zoom}
                                    title="Desbanir usuário"
                                  >
                                    <Button
                                      className={classes.deleteButton}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        handleUnKick(row.contact_id, row.email);
                                      }}
                                      disabled // @TODO IMPLEMENTS METHOD
                                    >
                                      <ThumbUp />
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    TransitionComponent={Zoom}
                                    title="Banir usuário"
                                  >
                                    <Button
                                      className={classes.deleteButton}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        handleChannelsUser(row._id);
                                        setOpenDialogKickUser(true);
                                        setSelectedLead({
                                          id: row.contact_id,
                                          email: row.email || row.contact_id,
                                        });
                                      }}
                                      disabled // @TODO IMPLEMENTS METHOD
                                    >
                                      <Gavel />
                                    </Button>
                                  </Tooltip>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </Container>
            </CardContent>

            <CardActions className={classes.footerPage}>
              <TablePagination
                component="div"
                count={count || 1}
                page={page}
                onChangePage={(_event, value) => {
                  setPage(value);
                }}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(event.target.value);
                  setPage(0);
                }}
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialog}
        onClose={(e) => handleClose(false, true)}
      >
        <DialogTitle id="form-dialog-title">
          Editar: {selectedLead.email || selectedLead.contact_id}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para atualizar este usuário, digite o novo endereço de e-mail:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Novo email"
            type="email"
            onChange={handleChangeUpdateEmail}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose("update", true)}
            color="primary"
            autoFocus
            disabled={!selectedLead.new_email || !selectedLead.email}
          >
            Sim
          </Button>
          <Button onClick={() => handleClose(false, true)} color="primary">
            Não
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialogSignature}
        onClose={(e) => handleClose(false, false)}
      >
        <DialogTitle id="form-dialog-title">
          Assinaturas (produtos):
        </DialogTitle>

        <DialogContent>
          {signatures &&
            signatures.map((signature) => (
              <DialogContentText>
                <span role="img" aria-label="checklist">
                  ✅
                </span>
                {signature.name}
              </DialogContentText>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false, false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialogSettings}
        onClose={() => setOpenDialogSettings(false)}
      >
        <Box sx={{ display: "flex" }} padding={3}>
          <FormLabel>Filtros para usuários</FormLabel>

          <FormGroup className={classes.dialogGroupSetting}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={filter.paid || false}
                  onChange={handleChange}
                  name="paid"
                />
              }
              label={
                filter.paid == null || filter.paid
                  ? "Assinantes"
                  : "Sem assinaturas"
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={filter.banned || false}
                  onChange={handleChange}
                  name="banned"
                />
              }
              label="Banidos"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={filter.logged || false}
                  onChange={handleChange}
                  name="logged"
                />
              }
              label="Logados"
            />
          </FormGroup>
        </Box>

        <DialogActions className={classes.dialogButtons}>
          <Button onClick={() => setFilter({})} color="primary">
            Limpar filtros
          </Button>

          <Button onClick={() => setOpenDialogSettings(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={openDialogKickUser}
        onClose={(e) => handleClose(false, false)}
      >
        <DialogTitle>{channels.email}</DialogTitle>
        <DialogContent>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.stickyheader} />
                <TableCell className={classes.stickyheader} />
              </TableRow>
            </TableHead>

            {channels &&
              channels.channel.map((row) => (
                <TableBody>
                  <TableRow key={row._id}>
                    <TableCell>
                      <Grid container wrap="nowrap" spacing={1}>
                        <Grid item xs>
                          <div className={classes.subtitle}>{row.name}</div>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="right">
                      {row.banned ? (
                        <Tooltip
                          TransitionComponent={Zoom}
                          title="Desbanir usuário"
                        >
                          <Button
                            className={classes.deleteButton}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              handleUnKick(row.contact_id, row.email);
                            }}
                          >
                            <ThumbUp />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          TransitionComponent={Zoom}
                          title="Banir usuário"
                        >
                          <Button
                            className={classes.deleteButton}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              // handleChannelsUser(row._id);
                              // setOpenDialogKickUser(true);
                              // setSelectedLead({
                              //   id: row.contact_id,
                              //   email: row.email || row.contact_id,
                              // });
                              handleClose("kick", true);
                            }}
                          >
                            <Gavel />
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>
        </DialogContent>
        {/* <DialogActions>
          <Button
            onClick={() => handleClose("kick", true)}
            color="primary"
            autoFocus
          >
            Sim
          </Button>
          <Button onClick={() => handleClose(false, false)} color="primary">
            Não
          </Button>
        </DialogActions> */}
      </Dialog>
    </Page>
  );
};

export default DashboardAuth;
