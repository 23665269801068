export const chatBots = {
  get: {
    method: "POST",
    endpoint: "bots",
    api: "chatBotApi",
  },
  post: ({username, token, status}) => ({
    method: "POST",
    endpoint: "bots/create",
    data: { username, token, status },
    api: "chatBotApi",
  }),
  patch: ({id, username, token, status}) => ({
    method: "PATCH",
    endpoint: `bots/${id}`,
    data: { username, token, status },
    api: "chatBotApi",
  }),
  delete: ({id}) => ({
    method: "DELETE",
    endpoint: `bots/${id}`,
    api: "chatBotApi",
  }),
}

export const chatbotUsers = {
  get: {
    method: "POST",
    endpoint: "users",
    api: "chatBotApi",
  },
  patch: ({id, email}) => ({
    method: "PATCH",
    endpoint: `users/${id}`,
    data: { email },
    api: "chatBotApi",
  }),
  delete: {},
}

export const chatbotChannels = {
  get: {
    method: "POST",
    endpoint: "channels",
    api: "chatBotApi",
  },
  post: ({name, channel_id, ext_product_ids, bot}) => ({
    method: "POST",
    endpoint: "channels/create",
    data: { name, channel_id, ext_product_ids, bot },
    api: "chatBotApi",
  }),
  patch: ({id, name, channel_id, ext_product_ids, bot}) => ({
    method: "PATCH",
    endpoint: `channels/${id}`,
    data: { name, channel_id, ext_product_ids, bot },
    api: "chatBotApi",
  }),
  delete: ({id}) => ({
    method: "DELETE",
    endpoint: `channels/${id}`,
    api: "chatBotApi",
  }),
}

export const recommendedWalletsIndex = {
  get: { method: "get", endpoint: "projects" }
}

export const recommendedWalletsProjects = {
  get: ({id}) => ({
    method: "GET",
    endpoint: `recommended_wallets/project/${id}`
  })
}

export const recommendedWalletsEdit = {
  get: ({id}) => ({
    method: "GET",
    endpoint: `recommended_wallets/${id}`
  }),
  patch: ({id, name, recommendations}) => ({
    method: "PATCH",
    endpoint: `recommended_wallets/${id}`,
    data: {name, recommendations}
  })
}

export const indicators = {
  get: (params) => ({
    method: "GET",
    endpoint: "indicators",
    params,
  })
}