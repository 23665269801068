import useStyles from "./styles";
import {
  Avatar,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import React from "react";
import { ArrowForward } from "@material-ui/icons";

const RecommendedWalletsTable = ({
  tableData = [],
  handleClick = () => {},
}) => {
  const classes = useStyles();
  return (
    <Container className={classes.cardContentFixedHeight}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.stickyheader} />
            <TableCell className={classes.stickyheader} />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map(({ id, name }) => (
            <TableRow key={id}>
              <TableCell>
                <Grid container wrap="nowrap" spacing={1}>
                  <Grid item>
                    <Avatar
                      src={`https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930`}
                    />
                  </Grid>
                  <Grid item xs>
                    <div className={classes.subtitle}>{name}</div>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="right">
                <Tooltip
                  TransitionComponent={Zoom}
                  title="Ver Carteiras Recomendadas"
                >
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleClick(id)}
                  >
                    <ArrowForward />
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default RecommendedWalletsTable;
