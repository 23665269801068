import React from "react";

import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Toolbar,
  Typography,
  Breadcrumbs,
  Link,
} from "@material-ui/core";

import { Dashboard } from "@material-ui/icons";

import { Page } from "../../../components";

import useStyles from "./styles";

const DashboardHome = () => {
  const classes = useStyles();

  return (
    <Page title="Dashboard" classes={classes}>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Breadcrumbs separator="›">
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">Home</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Toolbar>
                <Dashboard size={15} />
                <CardHeader className={classes.title} title="Home" />
              </Toolbar>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default DashboardHome;
