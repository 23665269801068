import React, { useCallback, useContext, useEffect } from "react";

import { Card, CardContent, Grid } from "@material-ui/core";

import { Page } from "../../../../components";

import useStyles from "./styles";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs";
import CardToolbar from "../../../../components/CardToolbar";
import { requestApi } from "../../../../helpers/requestApi";
import { useParams } from "react-router-dom";
import ToolsContext from "../../../../context/ToolsContext/ToolsContext";
import { useSnackbar } from "notistack";
import { AccountBalanceWallet } from "@material-ui/icons";
import { RecommendationTable } from "../../../../components/tables";

const RecommendedWalletsNew = () => {
  const classes = useStyles();
  const { id } = useParams();

  const { setHandleUpload, recommendations, setRecommendations } =
    useContext(ToolsContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = useCallback(
    async (inputValue) => {
      try {
        await requestApi({
          method: "POST",
          endpoint: "recommended_wallets",
          data: { name: inputValue, project_id: id, recommendations },
        });
        enqueueSnackbar("Sucesso!", { variant: "info" });
      } catch (err) {
        enqueueSnackbar("Algo deu errado!", { variant: "error" });
      }
    },
    [enqueueSnackbar, id, recommendations]
  );

  useEffect(() => {
    setHandleUpload(() => handleUpload);
  }, [handleUpload, setHandleUpload]);

  useEffect(() => {
    setRecommendations([]);
  }, [setRecommendations]);

  return (
    <Page title="Carteiras Recomendadas" classes={classes}>
      <Grid container spacing={3}>
        <CustomBreadcrumbs />
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <CardToolbar
                title="Nova Carteira Recomendada"
                uploadTool={true}
                icon={AccountBalanceWallet}
              />
              <RecommendationTable recommendations={recommendations} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default RecommendedWalletsNew;
