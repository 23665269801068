import React from "react";

import { Link } from "react-router-dom";

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Zoom,
  List,
  Divider,
  ListSubheader,
} from "@material-ui/core";

import {
  Dvr,
  Home,
  VpnKey,
  Group,
  WhatsApp,
  Facebook,
  Telegram,
  Comment,
  Notifications,
  EmojiObjects,
  AccountCircle,
  PowerSettingsNewRounded,
  InsertComment,
  ShowChart,
  AccountBalanceWallet,
  Chat,
  CastConnected,
} from "@material-ui/icons";

import store from "store";

const socialMedia = [
  { rede: "Dashboard", icon: Home, route: "/" },
  { rede: "Divisor", icon: Divider, route: "" },
  { rede: "Mídia Social", icon: Divider, route: "" },
  { rede: "OneSignal", icon: Notifications, route: "/onesignal" },
  { rede: "WhatsApp", icon: WhatsApp, route: "/whatsapp" },
  { rede: "Facebook", icon: Facebook, route: "/facebook" },
  { rede: "Telegram", icon: Telegram, route: "/telegram" },
  { rede: "Mensagens", icon: Comment, route: "/messages" },
];

const containerMedia = [
  { rede: "Usuários", icon: VpnKey, route: "/authentication" },
  { rede: "Canais", icon: Dvr, route: "/channel" },
  { rede: "Sugestões", icon: EmojiObjects, route: "/suggestion" }
];

const containerLevanteCorp = [
  { rede: "Usuários", icon: Group, route: "/corp/users" },
  { rede: "Mensagens", icon: InsertComment, route: "/corp/messages" },
];

const dashboardSetup = [
  { rede: "Usuários", icon: AccountCircle, route: "/users" },
];

const levanteLists = [
  {rede: "Indicadores", icon: ShowChart, route: "/levante/indicators"},
  {rede: "Carteiras Recomendadas", icon: AccountBalanceWallet, route: "/levante/recommended_wallets"}
]

const chatBot = [
  {rede: "Usuários", icon: Group, route: "/chatbot/users"},
  {rede: "Bots", icon: Chat, route: "/chatbot"},
  {rede: "Channels", icon: CastConnected, route: "/chatbot/channels"}
]

export const mainListItems = (
  <List>
    {socialMedia.map((social) => {
      if (social.rede === "Divisor") return <social.icon key={social.rede} />;

      if (social.rede === "Mídia Social") {
        return (
          <ListSubheader inset key={social.rede}>
            {social.rede}
          </ListSubheader>
        );
      }

      return (
        <Link
          key={social.rede}
          style={{
            textDecoration: "none",
            color: "#000000",
          }}
          to={social.route}
        >
          <ListItem button>
            <ListItemIcon>
              <Tooltip
                TransitionComponent={Zoom}
                title={social.rede}
                placement="right"
              >
                {<social.icon />}
              </Tooltip>
            </ListItemIcon>

            <ListItemText primary={social.rede} />
          </ListItem>
        </Link>
      );
    })}
  </List>
);

export const containerList = (
  <List>
    <ListSubheader inset>Levante Ideias</ListSubheader>
    {containerMedia.map((social) => (
      <Link
        key={social.rede}
        style={{
          textDecoration: "none",
          color: "#000000",
        }}
        to={social.route}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip
              TransitionComponent={Zoom}
              title={social.rede}
              placement="right"
            >
              {<social.icon />}
            </Tooltip>
          </ListItemIcon>

          <ListItemText primary={social.rede} />
        </ListItem>
      </Link>
    ))}
  </List>
);

export const containerLevanteCorpList = (
  <List>
    <ListSubheader inset>Levante Corp</ListSubheader>
    {containerLevanteCorp.map((social) => (
      <Link
        key={social.rede}
        style={{
          textDecoration: "none",
          color: "#000000",
        }}
        to={social.route}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip
              TransitionComponent={Zoom}
              title={social.rede}
              placement="right"
            >
              {<social.icon />}
            </Tooltip>
          </ListItemIcon>

          <ListItemText primary={social.rede} />
        </ListItem>
      </Link>
    ))}
  </List>
);

export const setupList = (
  <List>
    <ListSubheader inset>Plataforma</ListSubheader>
    {dashboardSetup.map((social) => (
      <Link
        key={social.rede}
        style={{
          textDecoration: "none",
          color: "#000000",
        }}
        to={social.route}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip
              TransitionComponent={Zoom}
              title={social.rede}
              placement="right"
            >
              {<social.icon />}
            </Tooltip>
          </ListItemIcon>

          <ListItemText primary={social.rede} />
        </ListItem>
      </Link>
    ))}
  </List>
);

export const secondaryListItems = (
  <List
    style={{
      marginTop: "auto",
    }}
  >
    <ListItem
      button
      component="a"
      onClick={() => store.remove("loggedIn")}
      href="/login"
    >
      <ListItemIcon>
        <Tooltip TransitionComponent={Zoom} title="Sair" placement="right">
          <PowerSettingsNewRounded />
        </Tooltip>
      </ListItemIcon>
      <ListItemText primary="Sair" />
    </ListItem>
  </List>
);

export const levanteList = (
  <List>
    <ListSubheader inset>Levante</ListSubheader>
    {levanteLists.map((social) => (
      <Link
        key={social.rede}
        style={{
          textDecoration: "none",
          color: "#000000",
        }}
        to={social.route}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip
              TransitionComponent={Zoom}
              title={social.rede}
              placement="right"
            >
              {<social.icon />}
            </Tooltip>
          </ListItemIcon>

          <ListItemText primary={social.rede} />
        </ListItem>
      </Link>
    ))}
  </List>
);

export const chatBots = (
  <List>
    <ListSubheader inset>Chatbot</ListSubheader>
    {chatBot.map((social) => (
      <Link
        key={social.rede}
        style={{
          textDecoration: "none",
          color: "#000000",
        }}
        to={social.route}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip
              TransitionComponent={Zoom}
              title={social.rede}
              placement="right"
            >
              {<social.icon />}
            </Tooltip>
          </ListItemIcon>

          <ListItemText primary={social.rede} />
        </ListItem>
      </Link>
    ))}
  </List>
);