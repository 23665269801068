import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Input,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import ToolsContext from "../../context/ToolsContext/ToolsContext";
import { parseCSV } from "../../helpers/parseCSV";

const PreviewTool = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState(null);
  const { setRecommendations } = useContext(ToolsContext);

  const handleClick = async () => {
    const recommendations = await parseCSV(file);
    setRecommendations(recommendations);
    setOpenDialog(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setOpenDialog(true)}
      >
        <AttachFile />
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle id="form-dialog-title">
          Nova Carteiras Recomendadas
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Envie o seu arquivo CSV</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Input
                variant="outlined"
                required
                fullWidth
                id="csv"
                label="Nome do produto"
                name="csv"
                type="file"
                onChange={({ target }) => setFile(target.files[0])}
              />
            </Grid>
          </Grid>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleClick()}
          >
            Visualizar Tabela
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PreviewTool;
