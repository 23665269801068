import axios from 'axios';

const setBaseURL = (api) => {
  const baseUrl = {
    coreApi: process.env.REACT_APP_BASE_URL,
    chatBotApi: process.env.REACT_APP_CHATBOT_BASE_URL
  }

  return baseUrl[api]
}

export const requestApi = async ({method, endpoint, params = {}, data = {}, headers = {}, api = "coreApi"}) => {
  const BASE_URL = setBaseURL(api)
  const options = {
    method,
    url: `${BASE_URL}/${endpoint}`,
    params,
    data,
    headers
  }
  try {
    const response = await axios(options);
    return {data: response.data, status: response.status};
  }
  catch (err) {
    if (err.response) {
      throw err.response
    } else {
      throw err
    }
  }
}