import { CardActions, TablePagination } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

const CardFooter = ({
  footerOptions = {
    count: 1,
    page: 0,
    rowsPerPage: 20,
    rowsPerPageOptions: [10, 25, 50, 100],
  },
  setFooterOptions = () => {},
}) => {
  const classes = useStyles();
  const { count, page, rowsPerPage, rowsPerPageOptions } = footerOptions;
  return (
    <CardActions className={classes.footerPage}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count || 1}
        page={page}
        onChangePage={(_event, value) => {
          setFooterOptions({ ...footerOptions, page: value });
        }}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={({ target }) => {
          setFooterOptions({ ...footerOptions, rowsPerPage: target.value });
        }}
      />
    </CardActions>
  );
};

export default CardFooter;
