import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const ChatbotChannelsFormDialog = ({
  openDialog,
  setOpenDialog,
  editing = false,
  chatbots,
  channelInfo = {
    id: "",
    name: "",
    bot: "",
    channel_id: "",
    ext_product_ids: [],
  },
  setChannelInfo,
  handleClick,
}) => {
  const { name, channel_id, ext_product_ids, bot } = channelInfo;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle id="form-dialog-title">
        {editing ? "Editar Channel" : "Criar Channel"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {editing
            ? `Para atualizar ${name}, preencha os campos abaixo:`
            : "Preencha os campos abaixo"}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          value={name}
          onChange={({ target }) =>
            setChannelInfo((prevState) => ({
              ...prevState,
              name: target.value,
            }))
          }
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="channel_id"
          label="channel_id"
          value={channel_id}
          type="text"
          onChange={({ target }) =>
            setChannelInfo((prevState) => ({
              ...prevState,
              channel_id: target.value,
            }))
          }
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="ext_product_ids"
          label="Ids dos produtos - separe os valores por vírgulas, ex: 10,22,33"
          type="text"
          value={ext_product_ids?.length ? ext_product_ids.join(",") : ""}
          onChange={({ target }) =>
            setChannelInfo((prevState) => ({
              ...prevState,
              ext_product_ids: target.value.split(","),
            }))
          }
          fullWidth
        />
        <Select
          autoFocus
          margin="dense"
          id="bot"
          label="bot"
          value={bot}
          onChange={({ target }) =>
            setChannelInfo((prevState) => ({
              ...prevState,
              bot: target.value,
            }))
          }
          fullWidth
        >
          {chatbots.map(({ _id, username }) => (
            <MenuItem key={_id} value={_id}>
              {username}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClick()} color="primary" autoFocus>
          Sim
        </Button>
        <Button
          onClick={() => {
            setOpenDialog(false);
          }}
          color="primary"
        >
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatbotChannelsFormDialog;
