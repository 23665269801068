import React from "react";
import { Toolbar, CardHeader } from "@material-ui/core";
import useStyles from "./styles";
import PreviewTool from "../PreviewTool";
import SearchTool from "../SearchTool";
import UploadTool from "../UploadTool";
import CreateTool from "../CreateTool";
import { Group } from "@material-ui/icons";

const CardToolbar = ({
  title = "Título da Toolbar",
  searchTool = false,
  uploadTool = false,
  createTool = false,
  createToolFunction = () => {},
  setSearchValue = () => {},
  icon: Icon,
}) => {
  const classes = useStyles();
  return (
    <Toolbar>
      {Icon ? <Icon size={15} /> : <Group size={15} />}
      <CardHeader className={classes.title} title={title} />
      {createTool && <CreateTool handleClick={createToolFunction} />}
      {searchTool && (
        <SearchTool classes={classes} setSearchValue={setSearchValue} />
      )}
      {uploadTool && (
        <>
          <UploadTool />
          <PreviewTool />
        </>
      )}
    </Toolbar>
  );
};

export default CardToolbar;
