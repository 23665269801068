import React, { useState } from "react";

import {
  Button,
  Paper,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import { Redirect } from "react-router-dom";

import { useSnackbar } from "notistack";

import { Footer } from "../../../components";

import Logo from "../../../assets/logo.png";

import useStyles from "./styles";

import { levanteMessaging } from "../../../services/api";

import isLoggedIn from "../../../helpers/logged";

import store from "store";

export default function SignInSide({ history }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [dataRegister, setDateRegister] = useState({});

  const [register, setRegister] = useState(false);

  if (isLoggedIn()) return <Redirect to="/" />;

  document.title = "Login | Levante";

  const handleChange = (event) => {
    event.persist();

    setData((formState) => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeTelegram = (event) => {
    event.persist();

    setDateRegister((formState) => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);

    await levanteMessaging
      .post("/users/login", data)
      .then(({ data }) => {
        if (data.email) {
          store.set("loggedIn", true);

          enqueueSnackbar(`Seja bem-vindo ${data.name}`, {
            variant: "success",
          });

          return history.push("/");
        }

        enqueueSnackbar(data.error, { variant: "error" });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });

    setLoading(false);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    setLoading(true);

    await levanteMessaging
      .post("/users/create", dataRegister)
      .then(({ data }) => {
        if (data.email) {
          enqueueSnackbar(`Conta cadastrada com sucesso.`, {
            variant: "success",
          });

          return setRegister(false);
        }

        enqueueSnackbar(data.error, { variant: "error" });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });

    setLoading(false);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography>
            <img className={classes.img} src={Logo} alt="Levante" />
          </Typography>
          {register ? (
            <form className={classes.form} onSubmit={handleRegister}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    id="name"
                    label="Nome"
                    autoFocus
                    onChange={handleChangeTelegram}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastname"
                    label="Sobrenome"
                    name="lastname"
                    autoComplete="lname"
                    onChange={handleChangeTelegram}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    onChange={handleChangeTelegram}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handleChangeTelegram}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  !dataRegister.email ||
                  !dataRegister.name ||
                  !dataRegister.lastname ||
                  !dataRegister.password
                }
                className={classes.submit}
              >
                Cadastrar
              </Button>
            </form>
          ) : (
            <form className={classes.form} onSubmit={handleLogin}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!data.email || !data.password}
                className={classes.submit}
              >
                Login
              </Button>
            </form>
          )}
          <Grid container>
            <Grid item xs>
              {/* <Button
                disableFocusRipple
                disableRipple
                style={{ textTransform: "none" }}
                variant="text"
                color="primary"
              >
                {register ? "" : "Esqueceu sua senha?"}
              </Button> */}
            </Grid>
            <Grid item>
              <Button
                onClick={(e) => setRegister(!register)}
                style={{ textTransform: "none" }}
                variant="text"
                color="primary"
              >
                {register ? "Já tenho conta" : "Criar conta"}
              </Button>
            </Grid>
          </Grid>
          <Footer />
        </div>

        <Backdrop open={loading} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Grid>
  );
}
