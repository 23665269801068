import React, { useContext, useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import useStyles from "./styles";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import { Page } from "../../../components";
import CardToolbar from "../../../components/CardToolbar";
import { ChatbotChannelsTable, LoadingTable } from "../../../components/tables";
import { requestApi } from "../../../helpers/requestApi";
import { ChatbotChannelsFormDialog } from "../../../components/dialogs";
import { Chat } from "@material-ui/icons";
import DataContext from "../../../context/DataContext/DataContext";
import useRequestApi from "../../../hooks/useRequestApi";
import { chatbotChannels } from "../../../shared/requests";

const ChatbotChannels = () => {
  const classes = useStyles();
  const [chatbots, setChatbots] = useState([]);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [channelInfo, setChannelInfo] = useState({});

  const { data, loading } = useContext(DataContext);
  const { fetchDataObject, postDataObject } = useRequestApi();

  const getChatbots = async () => {
    const { data } = await requestApi({
      method: "POST",
      endpoint: "bots",
      api: "chatBotApi",
    });
    setChatbots(data.bots);
  };

  const handleSubmit = async () => {
    await postDataObject(
      chatbotChannels.post(channelInfo),
      chatbotChannels.get
    );
  };

  useEffect(() => {
    fetchDataObject(chatbotChannels.get);
    getChatbots();
  }, [fetchDataObject]);

  return (
    <Page title="Channels" classes={classes}>
      <Grid container spacing={3}>
        <CustomBreadcrumbs />
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <CardToolbar
                title="Channels"
                searchTool={true}
                createTool={true}
                createToolFunction={() => setOpenFormDialog(true)}
                icon={Chat}
              />
              {loading ? (
                <LoadingTable />
              ) : (
                <ChatbotChannelsTable
                  tableData={data?.channels}
                  chatbots={chatbots}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ChatbotChannelsFormDialog
        openDialog={openFormDialog}
        setOpenDialog={setOpenFormDialog}
        chatbots={chatbots}
        channelInfo={channelInfo}
        setChannelInfo={setChannelInfo}
        handleClick={handleSubmit}
      />
    </Page>
  );
};

export default ChatbotChannels;
