import React from "react";

import { SnackbarProvider } from "notistack";

import GlobalTheme from "./styles/global";

import Routes from "./routes";
import { ToolsProvider } from "./context";
import DataProvider from "./context/DataContext/DataProvider";
const App = () => {
  return (
    <GlobalTheme>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <DataProvider>
        <ToolsProvider >
          <Routes />
        </ToolsProvider>
        </DataProvider>
      </SnackbarProvider>
    </GlobalTheme>
  );
};

export default App;
