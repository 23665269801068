import React from "react";

import { Box, Typography } from "@material-ui/core";

const Footer = () => {
  return (
    <Box pt={2}>
      <Typography variant="body2" color="textSecondary" align="center">
        {`Copyright © Levante Ideias de Investimentos ${new Date().getFullYear()}`}
      </Typography>
    </Box>
  );
};

export default Footer;
