import React, { useContext, useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import useStyles from "./styles";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import { Page } from "../../../components";
import CardToolbar from "../../../components/CardToolbar";
import { ChatbotsTable, LoadingTable } from "../../../components/tables";
import { ChatbotFormDialog } from "../../../components/dialogs";
import { Chat } from "@material-ui/icons";
import { chatBots } from "../../../shared/requests";
import DataContext from "../../../context/DataContext/DataContext";
import useRequestApi from "../../../hooks/useRequestApi";

const Chatbots = () => {
  const classes = useStyles();
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [chatbotInfo, setChatbotInfo] = useState({});
  const { data, loading } = useContext(DataContext);
  const { fetchDataObject, postDataObject } = useRequestApi();

  const handleSubmit = async () => {
    await postDataObject(chatBots.post(chatbotInfo), chatBots.get);
  };

  useEffect(() => {
    fetchDataObject(chatBots.get);
  }, [fetchDataObject]);

  return (
    <Page title="Chatbots" classes={classes}>
      <Grid container spacing={3}>
        <CustomBreadcrumbs />
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <CardToolbar
                title="Chatbots"
                searchTool={true}
                createTool={true}
                createToolFunction={() => setOpenFormDialog(true)}
                icon={Chat}
              />
              {loading ? (
                <LoadingTable />
              ) : (
                <ChatbotsTable tableData={data?.bots} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ChatbotFormDialog
        openDialog={openFormDialog}
        setOpenDialog={setOpenFormDialog}
        chatbotInfo={chatbotInfo}
        setChatbotInfo={setChatbotInfo}
        handleClick={handleSubmit}
      />
    </Page>
  );
};

export default Chatbots;
