import React, { useState, useEffect, useCallback, useContext } from "react";

import { Card, CardContent, Grid } from "@material-ui/core";

import { Page } from "../../../components";

import useStyles from "./styles";

import IndicatorsTable from "../../../components/IndicatorsTable";
import CardFooter from "../../../components/CardFooter";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import CardToolbar from "../../../components/CardToolbar";
import { ShowChart } from "@material-ui/icons";
import DataContext from "../../../context/DataContext/DataContext";
import useRequestApi from "../../../hooks/useRequestApi";
import { indicators } from "../../../shared/requests";

const Indicators = () => {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");
  const [footerOptions, setFooterOptions] = useState({
    count: 3120,
    page: 0,
    rowsPerPage: 20,
    rowsPerPageOptions: [10, 20, 50, 100],
  });

  const { data } = useContext(DataContext);
  const { fetchDataObject } = useRequestApi();

  const getIndicators = useCallback(async () => {
    const { page, rowsPerPage } = footerOptions;
    const params = {
      page_index: page + 1,
      page_size: rowsPerPage,
      search: searchValue,
    };
    fetchDataObject(indicators.get(params));
  }, [fetchDataObject, footerOptions, searchValue]);

  useEffect(() => {
    getIndicators();
  }, [getIndicators]);

  return (
    <Page title="Indicators" classes={classes}>
      <Grid container spacing={3}>
        <CustomBreadcrumbs />
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <CardToolbar
                searchTool={true}
                setSearchValue={setSearchValue}
                title={"Indicators"}
                icon={ShowChart}
              />
              <IndicatorsTable
                indicators={data?.stock_exchanges}
                getIndicators={getIndicators}
              />
              <CardFooter
                footerOptions={footerOptions}
                setFooterOptions={setFooterOptions}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Indicators;
