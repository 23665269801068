import React, { useCallback, useContext, useEffect } from "react";

import { Card, CardContent, Grid } from "@material-ui/core";

import { Page } from "../../../../components";

import useStyles from "./styles";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs";
import CardToolbar from "../../../../components/CardToolbar";
import { useParams } from "react-router-dom";
import ToolsContext from "../../../../context/ToolsContext/ToolsContext";
import { AccountBalanceWallet } from "@material-ui/icons";
import {
  LoadingTable,
  RecommendationTable,
} from "../../../../components/tables";
import DataContext from "../../../../context/DataContext/DataContext";
import useRequestApi from "../../../../hooks/useRequestApi";
import { recommendedWalletsEdit } from "../../../../shared/requests";
import LoadingTool from "../../../../components/tools/LoadingTool";

const RecommendedWalletsEdit = () => {
  const classes = useStyles();
  const { id } = useParams();

  const { recommendations, setHandleUpload, setRecommendations } =
    useContext(ToolsContext);

  const { loading, data } = useContext(DataContext);
  const { fetchDataObject, postDataObject } = useRequestApi();

  useEffect(() => {
    fetchDataObject(recommendedWalletsEdit.get({ id }));
  }, [fetchDataObject, id]);

  const handleUpload = useCallback(
    (inputValue) => {
      postDataObject(
        recommendedWalletsEdit.patch({ id, name: inputValue, recommendations }),
        recommendedWalletsEdit.get({ id })
      );
    },
    [id, postDataObject, recommendations]
  );

  useEffect(() => {
    setHandleUpload(() => handleUpload);
  }, [handleUpload, setHandleUpload]);

  useEffect(() => {
    setRecommendations(null);
  }, [setRecommendations]);

  const showRecommendations = useCallback(() => {
    if (recommendations) return recommendations;
    if (data?.recommended_wallet) {
      return data.recommended_wallet.recommendations;
    }
  }, [data, recommendations]);

  useEffect(() => {
    showRecommendations();
  }, [recommendations, showRecommendations]);

  return (
    <Page title="Carteiras Recomendadas" classes={classes}>
      <Grid container spacing={3}>
        <CustomBreadcrumbs />
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              {loading ? (
                <>
                  <LoadingTool />
                  <LoadingTable />
                </>
              ) : (
                <>
                  <CardToolbar
                    title={data?.recommended_wallet?.name}
                    uploadTool={true}
                    icon={AccountBalanceWallet}
                  />
                  <RecommendationTable
                    recommendations={showRecommendations()}
                  />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default RecommendedWalletsEdit;
