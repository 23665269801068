import useStyles from "./styles";
import {
  Avatar,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import React, { useState } from "react";
import { Edit, Info } from "@material-ui/icons";
import { ChatbotUserEditDialog } from "../../dialogs";

const ChatbotUsersTable = ({ tableData = [], handleClick = () => {} }) => {
  const openShowDialog = () => {};
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const classes = useStyles();

  const handleOpenEditDialog = (id, email) => {
    setUserInfo({ id, email });
    setOpenEditDialog(true);
  };

  return (
    <Container className={classes.cardContentFixedHeight}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.stickyheader} />
            <TableCell className={classes.stickyheader} />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map(({ _id, email, kind }) => (
            <TableRow key={_id}>
              <TableCell>
                <Grid container wrap="nowrap" spacing={1}>
                  <Grid item>
                    <Avatar>{email?.toUpperCase().substring(0, 1)}</Avatar>
                  </Grid>
                  <Grid item xs>
                    <div className={classes.subtitle}>{email}</div>
                    <div className={classes.description}>{kind}</div>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="right">
                <Tooltip TransitionComponent={Zoom} title="Editar Usuário">
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenEditDialog(_id, email)}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="Detalhes">
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => openShowDialog(_id)}
                  >
                    <Info />
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ChatbotUserEditDialog
        openDialog={openEditDialog}
        setOpenDialog={setOpenEditDialog}
        userInfo={userInfo}
      />
    </Container>
  );
};

export default ChatbotUsersTable;
