import React, { useState } from 'react';
import ToolsContext from './ToolsContext';

function ToolsProvider({ children }) {
  const [recommendations, setRecommendations] = useState(null);
  const [handleUpload, setHandleUpload] = useState(() => {})


  const state = {
    recommendations,
    setRecommendations, 
    handleUpload,
    setHandleUpload
  };

  return (
    <ToolsContext.Provider value={state}>
      {children}
    </ToolsContext.Provider>
  );
}

export default ToolsProvider;