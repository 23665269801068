import useStyles from "./styles";
import {
  Avatar,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import React, { useState } from "react";
import { Delete, Edit } from "@material-ui/icons";
import { ChatbotFormDialog, DeleteDialog } from "../../dialogs";
import active from "../../../assets/active.png";
import inactive from "../../../assets/inactive.png";
import useRequestApi from "../../../hooks/useRequestApi";
import { chatBots } from "../../../shared/requests";

const ChatbotUsersTable = ({ tableData = [] }) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [chatbotInfo, setChatbotInfo] = useState({});
  const { postDataObject } = useRequestApi();
  const classes = useStyles();

  const handleSubmit = async () => {
    const { id, status, token, username } = chatbotInfo;
    await postDataObject(
      chatBots.patch({ id, status, token, username }),
      chatBots.get
    );
  };

  const handleDelete = async () => {
    const { id } = chatbotInfo;
    await postDataObject(chatBots.delete({ id }), chatBots.get);
  };

  return (
    <Container className={classes.cardContentFixedHeight}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.stickyheader} />
            <TableCell className={classes.stickyheader} />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map(({ _id, username, token, status }) => (
            <TableRow key={_id}>
              <TableCell>
                <Grid container wrap="nowrap" spacing={1}>
                  <Grid item>
                    <Avatar src={status === "active" ? active : inactive} />
                  </Grid>
                  <Grid item xs>
                    <div className={classes.subtitle}>{username}</div>
                    <div className={classes.description}>{token}</div>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="right">
                <Tooltip TransitionComponent={Zoom} title="Editar Usuário">
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setChatbotInfo({ id: _id, username, token, status });
                      setOpenDeleteDialog(true);
                    }}
                  >
                    <Delete />
                  </Button>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="Editar Usuário">
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setChatbotInfo({ id: _id, username, token, status });
                      setOpenEditDialog(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ChatbotFormDialog
        openDialog={openEditDialog}
        setOpenDialog={setOpenEditDialog}
        chatbotInfo={chatbotInfo}
        setChatbotInfo={setChatbotInfo}
        editing={true}
        handleClick={handleSubmit}
      />
      <DeleteDialog
        id={chatbotInfo.id}
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        handleClick={handleDelete}
      />
    </Container>
  );
};

export default ChatbotUsersTable;
