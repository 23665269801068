import React, { useState, useEffect } from "react";

import {
  Toolbar,
  Container,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Avatar,
  Tooltip,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@material-ui/core";

import { Dvr, Edit, Add, Delete } from "@material-ui/icons";

import { Skeleton } from "@material-ui/lab";

import { useSnackbar } from "notistack";

import { Page } from "../../../components";

import { levanteBotGateway } from "../../../services/api";

import useStyles from "./styles";

const DashboardChannel = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [dataChannels, setDataChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLead, setSelectedLead] = useState({});
  const [skeletonRows] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [dataRegister, setDateRegister] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRegisterChannel = (e) => {
    e.preventDefault();

    setLoading(true);

    levanteBotGateway
      .post(
        "/channels?token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz",
        dataRegister
      )
      .then(({ data }) => {
        if (data) {
          setLoading(false);
          setOpenCreateDialog(false);
          setRefresh(true);

          return enqueueSnackbar(`Canal ${data.name} criado com sucesso.`, {
            variant: "success",
          });
        }

        enqueueSnackbar(data.error, { variant: "error" });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const handleChangeChannel = (event) => {
    event.persist();

    if (event.target.name === "name") {
      setDateRegister((data) => ({
        ...data,
        product: event.target.value
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(" ", "_"),
      }));
    }

    setDateRegister((formState) => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleUpdate = () => {
    levanteBotGateway
      .patch(
        `/channels/${selectedLead._id}?token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`,
        {
          url: selectedLead.new_url,
        }
      )
      .then(({ data }) => {
        if (data) {
          setRefresh(true);

          return enqueueSnackbar(
            `${selectedLead.new_url} atualizado com sucesso.`,
            {
              variant: "success",
            }
          );
        }

        enqueueSnackbar(data.error, { variant: "error" });
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}.`, { variant: "error" });
      });
  };

  const handleDelete = () => {
    levanteBotGateway
      .delete(
        `/channels/${selectedLead._id}?token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`
      )
      .then(({ data }) => {
        if (data) {
          setRefresh(true);

          return enqueueSnackbar(`${selectedLead.name} deletado com sucesso.`, {
            variant: "success",
          });
        }

        enqueueSnackbar(data.error, { variant: "error" });
      })
      .catch((err) => {
        enqueueSnackbar(`${err.message}.`, { variant: "error" });
      });
  };

  const handleChangeUpdateChannel = (event) => {
    event.persist();

    setSelectedLead((lead) => ({
      ...lead,
      new_url: event.target.value,
    }));
  };

  const handleClose = (action, popup) => {
    setOpenDialog(false);
    setOpenCreateDialog(false);
    setOpenDeleteDialog(false);

    if (action === "update") {
      return handleUpdate();
    }

    if (action === "delete") {
      return handleDelete();
    }

    setSelectedLead({});

    if (popup) {
      enqueueSnackbar("Operação cancelada.", { variant: "info" });
    }
  };

  useEffect(() => {
    setLoading(true);
    setRefresh(false);

    function loadUsers() {
      levanteBotGateway
        .get(
          `/channels/?skip=${
            page * rowsPerPage
          }&limit=${rowsPerPage}&token=554a2ab4-rvo8-041a-anb3-ba957p3ag7qz`
        )
        .then(({ data }) => {
          setLoading(false);
          setCount(data[0].total);
          setDataChannels(data.slice(1, data.length));
        });
    }

    loadUsers();
  }, [page, rowsPerPage, refresh]);

  return (
    <Page title="Canais" classes={classes}>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Breadcrumbs separator="›">
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">Canais</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <Toolbar>
                <Dvr size={15} />
                <CardHeader
                  className={classes.title}
                  title={`BOT - Auth ${count > 1 ? `${count} canais` : ""}`}
                />
                <div className={classes.search}>
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setOpenCreateDialog(true);
                    }}
                  >
                    <Add />
                  </Button>
                </div>
              </Toolbar>
              <Container className={classes.cardContentFixedHeight}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.stickyheader} />
                      <TableCell className={classes.stickyheader} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading
                      ? skeletonRows.map((element) => (
                          <TableRow key={element}>
                            <TableCell colSpan={6}>
                              <Skeleton animation="wave" height={44} />
                            </TableCell>
                          </TableRow>
                        ))
                      : dataChannels.map((row) => (
                          <TableRow key={row._id}>
                            <TableCell>
                              <Grid container wrap="nowrap" spacing={1}>
                                <Grid item>
                                  <Avatar>
                                    {row.name.toUpperCase().substring(0, 1)}
                                  </Avatar>
                                </Grid>
                                <Grid item xs>
                                  <div className={classes.subtitle}>
                                    {row.name}
                                  </div>
                                  <div className={classes.description}>
                                    {row.url || "url"}
                                  </div>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip
                                TransitionComponent={Zoom}
                                title="Editar canal"
                              >
                                <Button
                                  className={classes.deleteButton}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setSelectedLead(row);
                                  }}
                                >
                                  <Edit />
                                </Button>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Zoom}
                                title="Deletar canal"
                              >
                                <Button
                                  className={classes.deleteButton}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    setOpenDeleteDialog(true);
                                    setSelectedLead(row);
                                  }}
                                >
                                  <Delete />
                                </Button>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Container>
            </CardContent>

            <CardActions className={classes.footerPage}>
              <TablePagination
                component="div"
                count={count || 1}
                page={page}
                onChangePage={(_event, value) => {
                  setPage(value);
                }}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(event.target.value);
                  setPage(0);
                }}
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialog}
        onClose={(e) => handleClose(false, true)}
      >
        <DialogTitle id="form-dialog-title">
          Editar: {selectedLead.name || selectedLead.product_id}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite agora o link de acesso para o canal do telegram:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            label="Novo link"
            type="text"
            onChange={handleChangeUpdateChannel}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose("update", true)}
            color="primary"
            autoFocus
            disabled={!selectedLead.new_url || !selectedLead.name}
          >
            Sim
          </Button>
          <Button onClick={() => handleClose(false, true)} color="primary">
            Não
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={(e) => handleClose(false, false)}
      >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente deletar: <b>{selectedLead.name || "Produto"}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose("delete", true)}
            color="primary"
            autoFocus
          >
            Sim
          </Button>
          <Button onClick={() => handleClose(false, false)} color="primary">
            Não
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openCreateDialog}
        onClose={(e) => handleClose(false, true)}
      >
        <DialogTitle id="form-dialog-title">Criar um novo canal</DialogTitle>
        <DialogContent>
          <DialogContentText>Digite o seu nome do produto:</DialogContentText>
          <form className={classes.form} onSubmit={handleRegisterChannel}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Nome do produto"
                  name="name"
                  autoComplete="name"
                  onChange={handleChangeChannel}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="url"
                  label="Link do canal"
                  name="url"
                  autoComplete="url"
                  onChange={handleChangeChannel}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!dataRegister.name || !dataRegister.url}
              className={classes.submit}
            >
              Cadastrar
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default DashboardChannel;
