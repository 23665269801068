import React, { useState, useEffect } from "react";

import {
  Toolbar,
  Container,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Avatar,
} from "@material-ui/core";

import { Comment, Delete } from "@material-ui/icons";

import { Skeleton } from "@material-ui/lab";

import { Page } from "../../../components";

import { levanteMessaging } from "../../../services/api";

import useStyles from "./styles";

const DashboardMessages = () => {
  const classes = useStyles();

  const [dataMessages, setDataMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(1);
  const [skeletonRows] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);

  useEffect(() => {
    setLoading(true);

    function loadUsers() {
      levanteMessaging
        .get(
          `/messages?skip=${
            page * rowsPerPage
          }&limit=${rowsPerPage}&token=8cb3eab1-p2o8-54c1-rt18-hs25gwa9aih7`
        )
        .then(({ data }) => {
          setLoading(false);
          setCount(data[0].total);
          setDataMessages(data.slice(1, data.length));
        });
    }

    loadUsers();
  }, [page, rowsPerPage]);

  return (
    <Page title="Messages" classes={classes}>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <Breadcrumbs separator="›">
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">Mensagens</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <Toolbar>
                <Comment size={15} />
                <CardHeader
                  className={classes.title}
                  title={`Total ${count > 1 ? `${count} mensagens` : ""}`}
                />
              </Toolbar>
              <Container className={classes.cardContentFixedHeight}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.stickyheader} />
                      <TableCell className={classes.stickyheader} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading
                      ? skeletonRows.map((element) => (
                          <TableRow key={element}>
                            <TableCell colSpan={6}>
                              <Skeleton animation="wave" height={44} />
                            </TableCell>
                          </TableRow>
                        ))
                      : dataMessages.map((row) => (
                          <TableRow key={row._id}>
                            <TableCell>
                              <Grid container wrap="nowrap" spacing={1}>
                                <Grid item>
                                  <Avatar>
                                    {row.data.userName
                                      .toUpperCase()
                                      .substring(0, 1)}
                                  </Avatar>
                                </Grid>
                                <Grid item xs>
                                  <div className={classes.subtitle}>
                                    {row.data.userName}
                                  </div>
                                  <div className={classes.description}>
                                    {row.data.message || "mensagem"}
                                  </div>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                disabled
                                className={classes.deleteButton}
                                variant="outlined"
                                color="primary"
                              >
                                <Delete />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Container>
            </CardContent>

            <CardActions className={classes.footerPage}>
              <TablePagination
                component="div"
                count={count || 1}
                page={page}
                onChangePage={(_event, value) => {
                  setPage(value);
                }}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(event.target.value);
                  setPage(0);
                }}
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default DashboardMessages;
