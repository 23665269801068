import React, { useContext, useEffect } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import useStyles from "./styles";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs";
import { Page } from "../../../components";
import CardToolbar from "../../../components/CardToolbar";
import { ChatbotUsersTable, LoadingTable } from "../../../components/tables";
import DataContext from "../../../context/DataContext/DataContext";
import { chatbotUsers } from "../../../shared/requests";
import useRequestApi from "../../../hooks/useRequestApi";

const ChatbotUsersShow = () => {
  const classes = useStyles();
  const { fetchDataObject } = useRequestApi();
  const { data, loading } = useContext(DataContext);

  useEffect(() => {
    fetchDataObject(chatbotUsers.get);
  }, [fetchDataObject]);

  return (
    <Page title="Chatbot Users" classes={classes}>
      <Grid container spacing={3}>
        <CustomBreadcrumbs />
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <CardToolbar title="Usuários" searchTool={true} />
              {loading ? (
                <LoadingTable />
              ) : (
                <ChatbotUsersTable tableData={data?.users} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ChatbotUsersShow;
