import React, { useContext, useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { Page } from "../../../../components";
import useStyles from "./styles";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs";
import CardToolbar from "../../../../components/CardToolbar";
import { useHistory } from "react-router-dom";
import { AccountBalanceWallet } from "@material-ui/icons";
import {
  LoadingTable,
  RecommendedWalletsTable,
} from "../../../../components/tables";
import DataContext from "../../../../context/DataContext/DataContext";
import useRequestApi from "../../../../hooks/useRequestApi";
import { recommendedWalletsIndex } from "../../../../shared/requests";

const RecommendedWalletsShow = () => {
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const { fetchDataObject } = useRequestApi();
  const { data, loading } = useContext(DataContext);

  const handleClick = (id) => {
    history.push(`/levante/recommended_wallets/projects/${id}`);
  };

  useEffect(() => {
    fetchDataObject(recommendedWalletsIndex.get);
  }, [fetchDataObject]);

  return (
    <Page title="Carteiras Recomendadas" classes={classes}>
      <Grid container spacing={3}>
        <CustomBreadcrumbs />
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <CardToolbar
                title="Selecione o Projeto"
                setSearchValue={setSearchValue}
                searchTool={true}
                icon={AccountBalanceWallet}
              />
              {loading ? (
                <LoadingTable />
              ) : (
                <RecommendedWalletsTable
                  tableData={data?.projects}
                  handleClick={(id) => handleClick(id)}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default RecommendedWalletsShow;
