import React from "react";

import { CssBaseline, Container } from "@material-ui/core";

import { Drawer, Footer } from "../";

const ContainerPage = ({ children, title, classes }) => {
  document.title = title + " | Levante";

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer classes={classes} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
        <Footer />
      </main>
    </div>
  );
};

export default ContainerPage;
