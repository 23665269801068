import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import useStyles from "./styles";
import { Page } from "../../../../components";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs";
import CardToolbar from "../../../../components/CardToolbar";
import { requestApi } from "../../../../helpers/requestApi";
import { useHistory, useParams } from "react-router-dom";
import { AccountBalanceWallet } from "@material-ui/icons";
import { RecommendedWalletsTable } from "../../../../components/tables";
import DataContext from "../../../../context/DataContext/DataContext";
import useRequestApi from "../../../../hooks/useRequestApi";
import { recommendedWalletsProjects } from "../../../../shared/requests";

const RecommendedWallets = () => {
  const [searchValue, setSearchValue] = useState("");
  const [project, setProject] = useState({ name: "" });
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const { data } = useContext(DataContext);
  const { fetchDataObject } = useRequestApi();

  const showWallet = (id) => {
    history.push(`/levante/recommended_wallets/${id}`);
  };

  const createWallet = () => {
    history.push(`/levante/recommended_wallets/projects/${id}/new`);
  };

  const getProjectName = useCallback(async () => {
    const { data } = await requestApi({
      method: "get",
      endpoint: `projects/${id}`,
    });
    setProject(data.project);
  }, [id]);

  useEffect(() => {
    getProjectName();
    fetchDataObject(recommendedWalletsProjects.get({ id }));
  }, [fetchDataObject, getProjectName, id]);

  return (
    <Page title="Carteiras Recomendadas" classes={classes}>
      <Grid container spacing={3}>
        <CustomBreadcrumbs />
        <Grid item xs={12} md={8} lg={12}>
          <Card>
            <CardContent>
              <CardToolbar
                title={project.name}
                setSearchValue={setSearchValue}
                searchTool={true}
                createTool={true}
                createToolFunction={createWallet}
                icon={AccountBalanceWallet}
              />
              <RecommendedWalletsTable
                tableData={data?.recommended_wallets}
                handleClick={(id) => showWallet(id)}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default RecommendedWallets;
