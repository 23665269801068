import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { chatbotUsers } from "../../../shared/requests";
import useRequestApi from "../../../hooks/useRequestApi";

const ChatbotUserEditDialog = ({ openDialog, setOpenDialog, userInfo }) => {
  const { postDataObject } = useRequestApi();
  const [email, setEmail] = useState(userInfo.email);
  const { id } = userInfo;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle id="form-dialog-title">Editar email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Para atualizar ${userInfo.email}, digite o novo endereço de e-mail:`}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Novo email"
          type="email"
          onChange={({ target }) => setEmail(target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            postDataObject(chatbotUsers.patch({ id, email }), chatbotUsers.get)
          }
          color="primary"
          autoFocus
        >
          Sim
        </Button>
        <Button
          onClick={() => {
            setOpenDialog(false);
            setEmail("");
          }}
          color="primary"
        >
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatbotUserEditDialog;
