import { create } from "axios";

export const levanteMessaging = create({
  baseURL: "https://levante-messaging.herokuapp.com/api/v1",
});

export const levanteBotGateway = create({
  baseURL: "https://levante-bot-gateway.herokuapp.com/api/v1",
});

export const levanteCorp = create({
  baseURL: "https://telegram.levantecorp.com.br/api/v1",
});
