import React from "react";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import {
  Auth,
  Login,
  User,
  Message,
  Telegram,
  Facebook,
  Whatsapp,
  Onesignal,
  Dashboard,
  Suggestion,
  Signature,
  Channel,
  CorpUser,
  CorpMessage,
  Indicators,
  RecommendedWallets,
  RecommendedWalletsProjects,
  RecommendedWalletsEdit,
  RecommendedWalletsNew,
  ChatbotUsers,
  Chatbots,
  ChatbotChannels,
} from "./pages";

import isLogged from "./helpers/logged";
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLogged() ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute path="/" exact component={Dashboard} />
        <PrivateRoute path="/levante" exact component={Dashboard} />
        <PrivateRoute path="/users" component={User} />
        <PrivateRoute path="/telegram" component={Telegram} />
        <PrivateRoute path="/facebook" component={Facebook} />
        <PrivateRoute path="/whatsapp" component={Whatsapp} />
        <PrivateRoute path="/messages" component={Message} />
        <PrivateRoute path="/onesignal" component={Onesignal} />
        <PrivateRoute path="/suggestion" component={Suggestion} />
        <PrivateRoute path="/signature" component={Signature} />
        <PrivateRoute path="/authentication" component={Auth} />
        <PrivateRoute path="/channel" component={Channel} />
        <PrivateRoute path="/corp/users" component={CorpUser} />
        <PrivateRoute path="/corp/messages" component={CorpMessage} />
        <PrivateRoute path="/levante/indicators" component={Indicators} />
        <PrivateRoute exact path="/levante/recommended_wallets" component={RecommendedWallets} />
        <PrivateRoute exact path="/levante/recommended_wallets/projects" component={RecommendedWallets} />
        <PrivateRoute exact path="/levante/recommended_wallets/:id" component={RecommendedWalletsEdit} />
        <PrivateRoute exact path="/levante/recommended_wallets/projects/:id" component={RecommendedWalletsProjects} />
        <PrivateRoute exact path="/levante/recommended_wallets/projects/:id/new" component={RecommendedWalletsNew} />
        <PrivateRoute exact path="/chatbot" component={Chatbots} />
        <PrivateRoute path="/chatbot/users" component={ChatbotUsers} />
        <PrivateRoute path="/chatbot/channels" component={ChatbotChannels} />
      </Switch>
    </BrowserRouter>
  );
}
