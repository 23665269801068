import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  stickyheader: {
    backgroundColor: "#FFFFFF !important",
  },
  cardContentFixedHeight: {
    maxWidth: "100%",
    maxHeight: 600,
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  subtitle: {
    marginTop: "1px",
    fontSize: "15px",
  },
  description: {
    fontSize: theme.spacing(1.5),
    position: "relative",
    color: "#808080",
  },
  deleteButton: {
    margin: theme.spacing(0.5),
    "&:hover": {
      color: theme.palette.error.contrastText,
      background: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
