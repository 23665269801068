import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";

const DeleteDialog = ({ id, openDialog, setOpenDialog, handleClick }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
      }}
    >
      <DialogTitle id="form-dialog-title">{`Confirmar deleção de ${id}`}</DialogTitle>
      <DialogContent>
        <Button
          type="Button"
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => handleClick()}
        >
          Confirmar Deleção
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialog;
