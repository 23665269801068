import {
  Avatar,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import IndicatorsDialog from "../IndicatorsDialog";
import { Edit, Info } from "@material-ui/icons";
import React, { useState } from "react";
import useStyles from "./styles";

const IndicatorsTable = ({ indicators = [], getIndicators }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [indicatorCode, setIndicatorCode] = useState("");
  return (
    <Container className={classes.cardContentFixedHeight}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.stickyheader} />
            <TableCell className={classes.stickyheader} />
          </TableRow>
        </TableHead>
        <TableBody>
          {indicators.map(({ stock_code, stock_name, logo_key }) => (
            <TableRow key={stock_code}>
              <TableCell>
                <Grid container wrap="nowrap" spacing={1}>
                  <Grid item>
                    <Avatar
                      src={`https://levante-app.s3.sa-east-1.amazonaws.com/${logo_key}`}
                    />
                  </Grid>
                  <Grid item xs>
                    <div className={classes.subtitle}>{stock_code}</div>
                    <div className={classes.description}>{stock_name}</div>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="right">
                <Tooltip TransitionComponent={Zoom} title="Detalhes">
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                  >
                    <Info />
                  </Button>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="Editar Logo">
                  <Button
                    className={classes.deleteButton}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setIndicatorCode(stock_code);
                      setOpenDialog(true);
                    }}
                  >
                    <Edit />
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <IndicatorsDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          indicatorCode={indicatorCode}
          getIndicators={getIndicators}
        />
      </Table>
    </Container>
  );
};

export default IndicatorsTable;
