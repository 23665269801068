import { InputBase } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React from "react";
import { DebounceInput } from "react-debounce-input";

const SearchTool = ({ classes, setSearchValue = () => {} }) => {
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <Search />
      </div>
      <DebounceInput
        element={InputBase}
        minLength={2}
        debounceTimeout={500}
        placeholder="Localizar..."
        onChange={({ target }) => setSearchValue(target.value)}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
};

export default SearchTool;
