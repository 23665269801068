import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { v4 as uuidv4 } from "uuid";

const RecommendationTable = ({ recommendations = [] }) => {
  const classes = useStyles();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    recommendations[0]
      ? setColumns(Object.keys(recommendations[0]))
      : setColumns([]);
  }, [recommendations]);
  return (
    <Container className={classes.cardContentFixedHeight}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={uuidv4()} className={classes.stickyheader}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {recommendations.map((data, i) => (
            <TableRow key={uuidv4()}>
              {columns.map((column) => (
                <TableCell key={uuidv4()}>{data[column]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default RecommendationTable;
