import React from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import useStyles from "./styles";
import { Skeleton } from "@material-ui/lab";

const LoadingTable = ({ rows = [1, 2, 3, 4, 5] }) => {
  const classes = useStyles();
  return (
    <Container className={classes.cardContentFixedHeight}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.stickyheader}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((element) => (
            <TableRow key={element}>
              <TableCell colSpan={6}>
                <Skeleton animation="wave" height={44} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default LoadingTable;
